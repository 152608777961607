import React, { useCallback } from 'react';
import cl from 'classnames';
import includes from 'lodash/includes';
import xor from 'lodash/xor';

import { GroupedCheckbox } from '../GroupedCheckbox';
import { Translate } from '../Translate';
import { GADataType } from '../../types';

export type CheckboxItemType = {
  i18nLabel?: string;
  id: string;
  label?: string;
};

export type CheckboxItemsType = CheckboxItemType[];

export interface CheckboxGroupProps {
  checkboxClassName?: string;
  disabled?: boolean;
  i18nLabel?: string;
  id?: string;
  inputWrapperClassName?: string;
  items: CheckboxItemsType;
  label?: string;
  labelClassName?: string;
  name?: string;
  onChange: (value: string[]) => void;
  value: string[];
}

function CheckboxGroup({
  checkboxClassName,
  dataGa,
  disabled,
  i18nLabel,
  id,
  inputWrapperClassName,
  items = [],
  label,
  labelClassName,
  name,
  onChange,
  value = []
}: CheckboxGroupProps & GADataType) {
  const handleChange = useCallback(
    ({ id }) => onChange && onChange(xor(value, [id])),
    [onChange, value]
  );

  return (
    <>
      {i18nLabel || label ? (
        <label
          htmlFor={id || name}
          className={cl(
            labelClassName ||
              'block text-sm font-medium text-gray-700 dark:text-gray-300'
          )}
        >
          {i18nLabel ? <Translate id={i18nLabel} /> : label}
        </label>
      ) : null}
      <div
        className={cl(inputWrapperClassName || 'mt-3 flex flex-col space-y-2')}
      >
        {items.map(({ id, i18nLabel, label }) => (
          <div className="w-full sm:w-1/2 mb-2" key={id}>
            <GroupedCheckbox
              checkboxClassName={checkboxClassName}
              checkboxWrapperClassName="flex items-center"
              checked={includes(value, id)}
              // dataGa={`${dataGa}-${label ? label : t(i18nLabel)}`}
              dataGa={dataGa}
              disabled={disabled}
              i18nLabel={i18nLabel}
              id={id}
              label={label}
              onChange={handleChange}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default CheckboxGroup;
