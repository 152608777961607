import React, { memo } from 'react';

import { GADataType, IsDisabled } from '../../../types';

import { FiltersProps, useFilters } from '../hooks/useFilters';

import { CheckboxGroup, CheckboxItemsType } from '../../CheckboxGroup';

type CheckboxGroupFilterValue = string[] | undefined;

interface CheckboxGroupFilterProps
  extends FiltersProps<CheckboxGroupFilterValue> {
  items: CheckboxItemsType;
  inputWrapperClassName?: string;
  disabled?: IsDisabled;
}

function CheckboxGroupFilter({
  dataGa,
  disabled,
  inputWrapperClassName,
  items,
  name,
  onChange,
  value
}: CheckboxGroupFilterProps & GADataType) {
  const { updatedValue, handleChange } = useFilters<CheckboxGroupFilterValue>({
    name,
    value,
    emptyValue: [],
    onChange
  });

  return (
    <CheckboxGroup
      dataGa={dataGa}
      disabled={disabled}
      inputWrapperClassName={inputWrapperClassName}
      items={items}
      name={name}
      onChange={handleChange}
      value={updatedValue || []}
    />
  );
}

export default memo(CheckboxGroupFilter);
