import React from 'react';

import { GADataType, IsDisabled } from '../../types';

import {
  ToggleButtonGroupBaseValue,
  ToggleButtonGroupData,
  ToggleButtonGroupOnChange
} from './ToggleButtonGroup.types';

import { ToggleButtonGroupItem } from './components/ToggleButtonGroupItem';

interface ToggleButtonGroupProps<T extends ToggleButtonGroupBaseValue> {
  value: T;
  data: ToggleButtonGroupData<T>;
  disabled?: IsDisabled;
  onChange: ToggleButtonGroupOnChange<T>;
}

function ToggleButtonGroup<T extends ToggleButtonGroupBaseValue>({
  data,
  dataGa,
  disabled,
  onChange,
  value
}: ToggleButtonGroupProps<T> & GADataType) {
  return (
    <>
      {data.map((dataItem) => (
        <ToggleButtonGroupItem
          dataGa={dataGa}
          key={dataItem.value}
          selectedValue={value}
          value={dataItem.value}
          disabled={disabled}
          i18nText={dataItem.i18nText}
          icon={dataItem.icon}
          iconClassName={dataItem.iconClassName}
          onChange={onChange}
        />
      ))}
    </>
  );
}

export default ToggleButtonGroup;
