import React, { useCallback, useMemo } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import {
  FetchLifestylesFilters,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { Translate } from '../../../../../helpers/Translate';

import {
  ToggleButtonGroup,
  ToggleButtonGroupData,
  ToggleButtonGroupOnChange
} from '../../../../../helpers/ToggleButtonGroup';

import { words, librariesKeys } from '../../../../../locales/keys';

const enum LifestylesPremiumContentAtFilterValue {
  FREE = 'free',
  PREMIUM = 'premium'
}

interface LifestylesPremiumContentAtFilterProps {
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'premiumContentAt' as const;

const toggleButtonGroupData: ToggleButtonGroupData<LifestylesPremiumContentAtFilterValue> =
  [
    {
      value: LifestylesPremiumContentAtFilterValue.FREE,
      i18nText: librariesKeys.free
    },
    {
      value: LifestylesPremiumContentAtFilterValue.PREMIUM,
      i18nText: librariesKeys.premium,
      icon: IconsEnum.CROWN_SOLID,
      iconClassName: '-mr-2 ml-2 h-4 w-4 text-yellow-500'
    }
  ];

function LifestylesPremiumContentAtFilter({
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesPremiumContentAtFilterProps) {
  const handleChange = useCallback<
    ToggleButtonGroupOnChange<LifestylesPremiumContentAtFilterValue>
  >(
    (value) => {
      switch (value) {
        case LifestylesPremiumContentAtFilterValue.FREE:
          changeLifestylesFilters({ [name]: { isNull: true } });
          break;
        case LifestylesPremiumContentAtFilterValue.PREMIUM:
          changeLifestylesFilters({ [name]: { isNotNull: true } });
          break;
        default:
          changeLifestylesFilters({}, [name]);
          break;
      }
    },
    [changeLifestylesFilters]
  );

  const value = useMemo(() => {
    const filterValue = lifestylesFilters?.[name];

    if (filterValue?.isNull) {
      return LifestylesPremiumContentAtFilterValue.FREE;
    }

    if (filterValue?.isNotNull) {
      return LifestylesPremiumContentAtFilterValue.PREMIUM;
    }

    return null;
  }, [lifestylesFilters]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={words.license} />
        </h6>
      </div>

      <div className="flex flex-wrap gap-2">
        <ToggleButtonGroup
          dataGa="lifestyles-license-filter"
          value={value}
          data={toggleButtonGroupData}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default LifestylesPremiumContentAtFilter;
