import React from 'react';

import {
  FetchLifestylesFilters,
  LifestyleClientID,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { useLifestylesAuthorsFilter } from './hooks/useLifestylesAuthorsFilter';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { CheckboxAlphabeticalGroupFilter } from '../../../../../helpers/filters/CheckboxAlphabeticalGroupFilter';
import { Translate } from '../../../../../helpers/Translate';
import {
  FetchMoreInfiniteButtonHelper,
  FetchMoreInfiniteButtonHelperPlacement
} from '../../../../../helpers/buttons/FetchMoreInfiniteButtonHelper';

import { categoriesKeys, words } from '../../../../../locales/keys';

interface LifestylesAuthorsFilterProps {
  clientIds?: LifestyleClientID[];
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'authorId' as const;

function LifestylesAuthorsFilter({
  clientIds,
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesAuthorsFilterProps) {
  const filterValue = lifestylesFilters?.[name]?.in;

  const {
    lifestyleAuthorsFilterSelectedData,
    lifestylesAuthorsFilterData,
    lifestyleAuthorsFilterErrorMessage,
    lifestyleAuthorsFilterSelectedErrorMessage,
    lifestyleAuthorsFilterFetched,
    lifestyleAuthorsFilterIsPlaceholderData,
    lifestyleAuthorsFilterFetchingNextPage,
    hasNextLifestyleAuthorsFilterPage,
    lifestyleAuthorsFilterSearchValue,
    loadMoreLifestyleAuthorsFilter,
    handleLifestyleAuthorsFilterSearch,
    handleChangeLifestyleAuthorsFilter
  } = useLifestylesAuthorsFilter({
    clientIds,
    selectedIds: filterValue,
    name,
    changeLifestylesFilters
  });

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.authors.plural} />
        </h6>
      </div>
      <AlertMessage
        message={
          lifestyleAuthorsFilterErrorMessage ||
          lifestyleAuthorsFilterSelectedErrorMessage
        }
      />

      <CheckboxAlphabeticalGroupFilter
        dataGa="lifestyles-authors-filter"
        name={name}
        value={filterValue as string[]}
        sortedItems={lifestylesAuthorsFilterData}
        selectedItems={lifestyleAuthorsFilterSelectedData}
        itemsFetched={
          lifestyleAuthorsFilterIsPlaceholderData ||
          lifestyleAuthorsFilterFetched
        }
        i18nSearchPlaceholder={categoriesKeys.authors.search}
        searchValue={lifestyleAuthorsFilterSearchValue}
        fetchMoreButton={
          <FetchMoreInfiniteButtonHelper
            dataGa="lifestyles-authors-filter"
            errorMessage={lifestyleAuthorsFilterErrorMessage}
            isLoading={lifestyleAuthorsFilterFetchingNextPage}
            hasNextPage={hasNextLifestyleAuthorsFilterPage}
            i18nText={words.showMore}
            wrapperClassName="relative p-2 text-center"
            className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
            onFetchMore={loadMoreLifestyleAuthorsFilter}
          />
        }
        onSearch={handleLifestyleAuthorsFilterSearch}
        onChange={handleChangeLifestyleAuthorsFilter}
      />
    </div>
  );
}

export default LifestylesAuthorsFilter;
