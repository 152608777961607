import React from 'react';

import {
  FetchLifestylesFilters,
  LifestylesFiltersNavBasePath,
  LifestylesI18nCustomBaseTitle,
  ChangeLifestylesFiltersFunc
} from '../../../../../lifestylesTypes';

import useLifestylesIndexPageCategoriesNavBody from './useLifestylesIndexPageCategoriesNavBody';

import { LifestylesIndexPageCategoriesNavCategory } from '../../LifestylesIndexPageCategoriesNav.types';

import { LifestylesIndexPageCategoriesNavHead } from '../LifestylesIndexPageCategoriesNavHead';
import { LifestylesIndexPageCategoriesNavList } from '../LifestylesIndexPageCategoriesNavList';

import { getChangeLifestylesFiltersCategoriesData } from '../../../../../utils/getChangeLifestylesFiltersCategoriesData';

import { LifestylePath } from '../../../../../LifestylePath';

import { lifestylesKeys } from '../../../../../../../locales/keys';

interface LifestylesIndexPageCategoriesNavProps {
  lifestyleCategories: LifestylesIndexPageCategoriesNavCategory[];
  filtersNavBasePath?: LifestylesFiltersNavBasePath;
  i18nCustomBaseTitle?: LifestylesI18nCustomBaseTitle;
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

function LifestylesIndexPageCategoriesNav({
  lifestyleCategories,
  filtersNavBasePath,
  i18nCustomBaseTitle,
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesIndexPageCategoriesNavProps) {
  const {
    currentParentCategory,
    currentChildCategory,
    handleResetCategoriesFilters,
    handleParentCategoryChangeFilters
  } = useLifestylesIndexPageCategoriesNavBody({
    lifestyleCategories,
    lifestylesFilters,
    changeLifestylesFilters
  });

  if (currentParentCategory && currentChildCategory) {
    return (
      <>
        <LifestylesIndexPageCategoriesNavHead
          text={currentChildCategory.localizedName}
          backHref={
            filtersNavBasePath
              ? LifestylePath.indexFiltersCustomBaseQuery(
                  filtersNavBasePath,
                  getChangeLifestylesFiltersCategoriesData({
                    lifestyleCategory: currentParentCategory,
                    categories: lifestyleCategories
                  }).changeFilters
                )
              : '#'
          }
          filtersNavBasePath={filtersNavBasePath}
          onChangeFiltersBack={handleParentCategoryChangeFilters}
        />
        <LifestylesIndexPageCategoriesNavList
          lifestyleCategories={lifestyleCategories}
          parentCategoryId={currentChildCategory.id}
          filtersNavBasePath={filtersNavBasePath}
          changeLifestylesFilters={changeLifestylesFilters}
        />
      </>
    );
  }

  if (currentParentCategory) {
    const backHrefWithoutBasePath = !filtersNavBasePath && '#';
    const backHrefWithBasePath = currentParentCategory.parent?.id
      ? LifestylePath.indexFiltersCustomBaseQuery(
          filtersNavBasePath,
          getChangeLifestylesFiltersCategoriesData({
            lifestyleCategory: currentParentCategory.parent,
            categories: lifestyleCategories
          }).changeFilters
        )
      : filtersNavBasePath;

    return (
      <>
        <LifestylesIndexPageCategoriesNavHead
          text={currentParentCategory.localizedName}
          backHref={backHrefWithoutBasePath || backHrefWithBasePath}
          filtersNavBasePath={filtersNavBasePath}
          onChangeFiltersBack={handleResetCategoriesFilters}
        />
        <LifestylesIndexPageCategoriesNavList
          lifestyleCategories={lifestyleCategories}
          parentCategoryId={currentParentCategory.id}
          filtersNavBasePath={filtersNavBasePath}
          changeLifestylesFilters={changeLifestylesFilters}
        />
      </>
    );
  }

  if (currentChildCategory) {
    return (
      <>
        <LifestylesIndexPageCategoriesNavHead
          text={currentChildCategory.localizedName}
          backHref={filtersNavBasePath || '#'}
          filtersNavBasePath={filtersNavBasePath}
          onChangeFiltersBack={handleResetCategoriesFilters}
        />
        <LifestylesIndexPageCategoriesNavList
          lifestyleCategories={lifestyleCategories}
          parentCategoryId={currentChildCategory.id}
          filtersNavBasePath={filtersNavBasePath}
          changeLifestylesFilters={changeLifestylesFilters}
        />
      </>
    );
  }

  return (
    <>
      <LifestylesIndexPageCategoriesNavHead
        i18nText={i18nCustomBaseTitle || lifestylesKeys.plural}
        filtersNavBasePath={filtersNavBasePath}
      />
      <LifestylesIndexPageCategoriesNavList
        lifestyleCategories={lifestyleCategories}
        filtersNavBasePath={filtersNavBasePath}
        changeLifestylesFilters={changeLifestylesFilters}
      />
    </>
  );
}

export default LifestylesIndexPageCategoriesNav;
