import React, { useCallback } from 'react';

import {
  FetchLifestylesFilters,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { Checkbox } from '../../../../../helpers/Checkbox';
import { Translate } from '../../../../../helpers/Translate';

import { formsFields, words } from '../../../../../locales/keys';

interface LifestylesFavoriteCheckboxFilterProps {
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
  withFavorite: boolean;
  withTeamFavorite: boolean;
}

const nameFavorite = 'favorite' as const;
const nameTeamFavorite = 'currentTeamFavorite' as const;

function LifestylesFavoriteCheckboxFilter({
  lifestylesFilters,
  changeLifestylesFilters,
  withFavorite,
  withTeamFavorite
}: LifestylesFavoriteCheckboxFilterProps) {
  const favoriteChecked = lifestylesFilters?.[nameFavorite];
  const handleFavoriteClick = useCallback(() => {
    if (favoriteChecked) {
      changeLifestylesFilters({}, [nameFavorite]);
    } else {
      changeLifestylesFilters({ [nameFavorite]: true });
    }
  }, [favoriteChecked, changeLifestylesFilters]);

  const teamFavoriteChecked = lifestylesFilters?.[nameTeamFavorite];

  const handleTeamFavoriteClick = useCallback(() => {
    if (teamFavoriteChecked) {
      changeLifestylesFilters({}, [nameTeamFavorite]);
    } else {
      changeLifestylesFilters({ [nameTeamFavorite]: true });
    }
  }, [teamFavoriteChecked, changeLifestylesFilters]);

  return (
    <div className="p-4 space-y-1.5">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={formsFields.favorites} />
        </h6>
      </div>

      {withFavorite && (
        <Checkbox
          dataGa="lifestyles-favorite-checkbox-filter"
          checkboxWrapperClassName="flex items-center"
          i18nLabel={words.showFavoriteOnly}
          checked={favoriteChecked}
          onChange={handleFavoriteClick}
        />
      )}

      {withTeamFavorite && (
        <Checkbox
          dataGa="lifestyles-favorite-checkbox-filter"
          checkboxWrapperClassName="flex items-center"
          i18nLabel={words.showFavoritesOfMyCompany}
          checked={teamFavoriteChecked}
          onChange={handleTeamFavoriteClick}
        />
      )}
    </div>
  );
}

export default LifestylesFavoriteCheckboxFilter;
