import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';

import {
  FetchLifestylesFilters,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { words } from '../../../../../locales/keys';

interface LifestylesFavoriteButtonFilterProps {
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'favorite' as const;

function LifestylesFavoriteButtonFilter({
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesFavoriteButtonFilterProps) {
  const checked = lifestylesFilters?.[name];
  const handleClick = useCallback(() => {
    if (checked) {
      changeLifestylesFilters({}, [name]);
    } else {
      changeLifestylesFilters({ [name]: true });
    }
  }, [checked, changeLifestylesFilters]);

  return (
    <PureTooltipIconButtonHelper
      className="py-1.5 pl-1.5 pr-1.5 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
      dataGa="lifestyles-favorite-filter"
      icon={checked ? IconsEnum.HEART_SOLID : IconsEnum.HEART_OUTLINE}
      iconClassName="h-5 w-5"
      tooltipI18nText={words.filterFavorite}
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleClick}
    />
  );
}

export default LifestylesFavoriteButtonFilter;
