import React from 'react';

import { ClassName, I18nText } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKeys
} from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import { OnSelectedLifestylesSidebarCloseAction } from '../../../../../../lifestyles/lifestylesTypes';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

import useSelectedLifestylesListItemDeleteButton from './useSelectedLifestylesListItemDeleteButton';

import {
  SelectedLifestylesListItemDeleteButtonSelectedLifestyle,
  SelectedLifestylesListItemDeleteButtonLifestylesSet
} from './SelectedLifestylesListItemDeleteButton.types';

interface SelectedLifestylesListItemDeleteButtonProps {
  selectedLifestyle: SelectedLifestylesListItemDeleteButtonSelectedLifestyle;
  lifestylesSet: SelectedLifestylesListItemDeleteButtonLifestylesSet;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<SelectedLifestylesListItemDeleteButtonLifestylesSet>;
}

function SelectedLifestylesListItemDeleteButton({
  selectedLifestyle,
  lifestylesSet,
  lifestylesSetCacheKeys,
  className,
  iconClassName,
  icon,
  i18nText,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache
}: SelectedLifestylesListItemDeleteButtonProps) {
  const { handleToggleSelected } = useSelectedLifestylesListItemDeleteButton({
    selectedLifestyle,
    lifestylesSet,
    lifestylesSetCacheKeys,
    onSelectedLifestylesSidebarClose,
    updateLifestylesSetCache
  });

  return (
    <>
      <PureIconButtonHelper
        className={className}
        dataGa="selected-lifestyle-delete-button"
        icon={icon}
        iconClassName={iconClassName}
        i18nText={i18nText}
        onClick={handleToggleSelected}
      />
    </>
  );
}

export default SelectedLifestylesListItemDeleteButton;
