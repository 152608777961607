import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  FetchLifestylesFilters,
  LifestyleClientID,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { useLifestyleColors } from '../../../hooks/useLifestyleColors';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { ColorsSelectFilter } from '../../../../../helpers/filters/ColorsSelectFilter';
import { Translate } from '../../../../../helpers/Translate';

import { getLifestyleClientIdsCacheKeyPart } from '../../../utils/getLifestyleClientIdsCacheKeyPart';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

import { LifestylesColorsFilterDataItem } from './LifestylesColorsFilter.types';

interface LifestylesColorsFilterProps {
  clientIds?: LifestyleClientID[];
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'mainColorIds' as const;

function LifestylesColorsFilter({
  clientIds,
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesColorsFilterProps) {
  const cacheKey = isEmpty(clientIds)
    ? LifestyleCache.colorsFilterCacheKey()
    : LifestyleCache.colorsLibraryFilterCacheKey(
        getLifestyleClientIdsCacheKeyPart({ clientIds })
      );

  const addInitialFilters = isEmpty(clientIds)
    ? undefined
    : { lifestyleClientIds: clientIds };

  const {
    lifestyleColors,
    lifestyleColorsErrorMessage,
    lifestyleColorsFetched,
    lifestyleColorsIsPlaceholderData
  } = useLifestyleColors({
    cacheKey,
    addInitialFilters
  });

  const lifestyleColorsItems = useMemo<LifestylesColorsFilterDataItem[]>(() => {
    return lifestyleColors.map((category) => ({
      value: category.id as string,
      label: category.localizedName,
      color: category.nameEn
    }));
  }, [lifestyleColors]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.colors.singular} />
        </h6>
      </div>
      <AlertMessage message={lifestyleColorsErrorMessage} />
      <LoadingSkeleton
        loaded={lifestyleColorsIsPlaceholderData || lifestyleColorsFetched}
      >
        <ColorsSelectFilter
          dataGa="lifestyles-colors-filter"
          name={name}
          items={lifestyleColorsItems}
          value={lifestylesFilters?.[name] as string[]}
          onChange={changeLifestylesFilters}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default LifestylesColorsFilter;
