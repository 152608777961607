import React, { useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';
import { IdFilterFields } from '../../../../../types';

import {
  FETCH_LIFESTYLE_AUTHORS_SELECTED_QUERY,
  FetchLifestyleAuthorsSelectedQueryResponse
} from '../../../queries/fetchLifestyleAuthorsSelected.query';
import { CACHE_FETCH_LIFESTYLE_AUTHORS_SELECTED_QUERY } from '../../../queries/fetchCacheLifestyleAuthorsSelected.query';

import { useFinPaginatedCategories } from '../../../../categories/hooks/useFinPaginatedCategories';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';
import { CategoriesPermissions } from '../../../../categories/categoriesConstants';

interface LifestylesAuthorsFilterBadgeItem {
  id: string;
  label: string;
}

const staleTime = 1000 * 60 * 60;

function LifestylesAuthorsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const filterValue = useMemo(() => filters?.[name]?.in || [], [filters, name]);

  const {
    categories: lifestyleAuthorsSelected,
    categoriesFilters: lifestyleAuthorsSelectedFilters,
    changeCategoriesFilters: changeLifestyleAuthorsSelectedFilters
  } = useFinPaginatedCategories<FetchLifestyleAuthorsSelectedQueryResponse>({
    action: CategoriesPermissions.READ_LIFESTYLE_CATEGORIES_INDEX_ES_QUERY,
    cacheKey: LifestyleCache.authorsSelectedFilterCacheKey(),
    query: FETCH_LIFESTYLE_AUTHORS_SELECTED_QUERY,
    cacheQuery: CACHE_FETCH_LIFESTYLE_AUTHORS_SELECTED_QUERY,
    initialFilters: {
      scope: { eq: FetchCategoriesScopes.SCENE_PERFORMERS },
      id: { in: filterValue }
    },
    options: {
      staleTime,
      keepPreviousData: true
    }
  });

  useEffect(() => {
    if (!isEqual(lifestyleAuthorsSelectedFilters?.id?.in, filterValue)) {
      changeLifestyleAuthorsSelectedFilters({
        id: { in: filterValue }
      });
    }
  }, [
    changeLifestyleAuthorsSelectedFilters,
    filterValue,
    lifestyleAuthorsSelectedFilters?.id?.in
  ]);

  const lifestyleAuthorsSelectedData = useMemo<
    LifestylesAuthorsFilterBadgeItem[]
  >(
    () =>
      lifestyleAuthorsSelected.map((category) => ({
        id: category.id as string,
        label: category.localizedName
      })),
    [lifestyleAuthorsSelected]
  );

  return (
    <ItemsFilterBadge
      dataGa="lifestyles-authors-filter-badge-clear"
      filters={filters}
      i18nLabel={categoriesKeys.authors.plural}
      items={lifestyleAuthorsSelectedData}
      name={name}
      onRemoveFilter={onRemoveFilter}
      filterField={IdFilterFields.IN}
    />
  );
}

export default LifestylesAuthorsFilterBadge;
