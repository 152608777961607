import React, { useMemo } from 'react';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import omit from 'lodash/omit';

import {
  FetchLifestylesFilters,
  LifestylesFiltersNavBasePath,
  LifestylesI18nCustomBaseTitle,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { FetchLifestyleCategoriesQueryResponse } from '../../../queries/fetchLifestyleCategories.query';

import { useLifestyleCategories } from '../../../hooks/useLifestyleCategories';
import { useFinLifestyleCategories } from '../../../hooks/useFinLifestyleCategories';

import { LifestylesIndexPageCategoriesNavBody } from './components/LifestylesIndexPageCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { LifestyleCache } from '../../../LifestyleCache';

interface LifestylesIndexPageCategoriesNavProps {
  filtersNavBasePath?: LifestylesFiltersNavBasePath;
  i18nCustomBaseTitle?: LifestylesI18nCustomBaseTitle;
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

function LifestylesIndexPageCategoriesNav({
  filtersNavBasePath,
  i18nCustomBaseTitle,
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesIndexPageCategoriesNavProps) {
  const {
    lifestyleCategories,
    lifestyleCategoriesErrorMessage,
    lifestyleCategoriesFetched,
    lifestyleCategoriesIsPlaceholderData
  } = useFinLifestyleCategories({
    cacheKey: LifestyleCache.notEmptyCategoriesCacheKey(),
    roomsByLifestyle: omit(lifestylesFilters, [
      'lifestyleCategoryId',
      'lifestyleParentCategoryIds'
    ])
  });

  const {
    lifestyleCategories: allLifestyleCategories,
    lifestyleCategoriesErrorMessage: allLifestyleCategoriesErrorMessage,
    lifestyleCategoriesFetched: allLifestyleCategoriesFetched,
    lifestyleCategoriesIsPlaceholderData:
      allLifestyleCategoriesIsPlaceholderData
  } = useLifestyleCategories({
    cacheKey: LifestyleCache.categoriesCacheKey()
  });

  const lifestyleCategoriesWithParents = useMemo<
    FetchLifestyleCategoriesQueryResponse[]
  >(() => {
    const parentCategories = compact(
      lifestyleCategories?.map((category) => category.parent)
    );

    const parentWithParentCategories = compact(
      parentCategories?.map(
        (category) =>
          find(allLifestyleCategories, { id: category.id }) || category
      )
    );

    return sortBy(
      uniqBy([...lifestyleCategories, ...parentWithParentCategories], 'id'),
      'name'
    );
  }, [allLifestyleCategories, lifestyleCategories]);

  return (
    <div className="p-4">
      <AlertMessage
        message={
          lifestyleCategoriesErrorMessage || allLifestyleCategoriesErrorMessage
        }
      />
      <LoadingSkeleton
        loaded={
          !lifestyleCategoriesIsPlaceholderData &&
          lifestyleCategoriesFetched &&
          !allLifestyleCategoriesIsPlaceholderData &&
          allLifestyleCategoriesFetched
        }
      >
        <LifestylesIndexPageCategoriesNavBody
          lifestyleCategories={lifestyleCategoriesWithParents}
          filtersNavBasePath={filtersNavBasePath}
          i18nCustomBaseTitle={i18nCustomBaseTitle}
          lifestylesFilters={lifestylesFilters}
          changeLifestylesFilters={changeLifestylesFilters}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default LifestylesIndexPageCategoriesNav;
