import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';

import { useLifestyleMaterials } from '../../../hooks/useLifestyleMaterials';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

interface LifestylesMaterialsFilterBadgeItem {
  id: string;
  label: string;
}

function LifestylesMaterialsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { lifestyleMaterials } = useLifestyleMaterials({
    cacheKey: LifestyleCache.materialsFilterCacheKey()
  });

  const LifestylesMaterialsFilterBadgeItems = useMemo<
    LifestylesMaterialsFilterBadgeItem[]
  >(() => {
    return lifestyleMaterials.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [lifestyleMaterials]);

  return (
    <ItemsFilterBadge
      dataGa="lifestyles-materials-filter-badge-clear"
      filters={filters}
      i18nLabel={categoriesKeys.materials.singular}
      items={LifestylesMaterialsFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
    />
  );
}

export default LifestylesMaterialsFilterBadge;
