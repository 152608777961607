import React, { useMemo, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  FetchLifestylesFilters,
  LifestyleClientID,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { useLifestyleStyles } from '../../../hooks/useLifestyleStyles';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckboxGroupFilter } from '../../../../../helpers/filters/CheckboxGroupFilter';
import { Translate } from '../../../../../helpers/Translate';

import { getLifestyleClientIdsCacheKeyPart } from '../../../utils/getLifestyleClientIdsCacheKeyPart';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

import { LifestylesStylesFilterDataItem } from './LifestylesStylesFilter.types';

interface LifestylesStylesFilterProps {
  clientIds?: LifestyleClientID[];
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'styleIds' as const;

function LifestylesStylesFilter({
  clientIds,
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesStylesFilterProps) {
  const cacheKey = isEmpty(clientIds)
    ? LifestyleCache.stylesFilterCacheKey()
    : LifestyleCache.stylesLibraryFilterCacheKey(
        getLifestyleClientIdsCacheKeyPart({ clientIds })
      );

  const addInitialFilters = isEmpty(clientIds)
    ? undefined
    : { lifestyleClientIds: clientIds };

  const {
    lifestyleStyles,
    lifestyleStylesErrorMessage,
    lifestyleStylesFetched,
    lifestyleStylesIsPlaceholderData
  } = useLifestyleStyles({
    cacheKey,
    addInitialFilters
  });

  const lifestylesStylesFilterData = useMemo<
    LifestylesStylesFilterDataItem[]
  >(() => {
    return lifestyleStyles.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [lifestyleStyles]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeLifestylesFilters(
        { [name]: changedFilters?.[name] || [] },
        removeFilters
      );
    },
    [changeLifestylesFilters]
  );

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.styles.singular} />
        </h6>
      </div>
      <AlertMessage message={lifestyleStylesErrorMessage} />
      <LoadingSkeleton
        loaded={lifestyleStylesIsPlaceholderData || lifestyleStylesFetched}
      >
        <CheckboxGroupFilter
          dataGa="lifestyles-styles-filter"
          inputWrapperClassName="flex flex-col sm:flex-row sm:flex-wrap -mb-2"
          name={name}
          items={lifestylesStylesFilterData}
          value={lifestylesFilters?.[name] as string[]}
          onChange={handleChange}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default LifestylesStylesFilter;
