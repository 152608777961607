import React, { memo } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';
import {
  FetchLifestylesClearLifestylesFilters,
  FetchLifestylesFilterFields,
  FetchLifestylesFilters,
  FetchLifestylesFiltersToPersist,
  FetchLifestylesFilterLifestyles
} from '../../lifestylesTypes';

import { BooleanFilterBadge } from '../../../../helpers/filterBadges/BooleanFilterBadge';
import { PureIconButtonHelper } from '../../../../helpers/buttons/PureIconButtonHelper';
import { LifestylesAuthorsFilterBadge } from '../filterBadges/LifestylesAuthorsFilterBadge';
import { LifestylesStylesFilterBadge } from '../filterBadges/LifestylesStylesFilterBadge';
import { LifestylesGammasFilterBadge } from '../filterBadges/LifestylesGammasFilterBadge';
import { LifestylesTonesFilterBadge } from '../filterBadges/LifestylesTonesFilterBadge';
import { LifestylesMaterialsFilterBadge } from '../filterBadges/LifestylesMaterialsFilterBadge';
import { LifestylesColorsFilterBadge } from '../filterBadges/LifestylesColorsFilterBadge';
import { LifestylesTypesFilterBadge } from '../filterBadges/LifestylesTypesFilterBadge';

import { isEveryPropEmpty } from '../../../../utils/isEveryPropEmpty';

import { words } from '../../../../locales/keys';

interface LifestylesAppliedFiltersProps {
  clearLifestylesFilters: FetchLifestylesClearLifestylesFilters;
  lifestylesFilters: FetchLifestylesFilters;
  filterLifestyles: FetchLifestylesFilterLifestyles;
  filtersToPersist?: FetchLifestylesFiltersToPersist;
}

function LifestylesAppliedFilters({
  clearLifestylesFilters,
  lifestylesFilters,
  filterLifestyles,
  filtersToPersist = []
}: LifestylesAppliedFiltersProps) {
  const persistantFilters: FetchLifestylesFiltersToPersist = [
    FetchLifestylesFilterFields.LIFESTYLE_PARENT_CATEGORY_IDS,
    FetchLifestylesFilterFields.LIFESTYLE_CATEGORY_ID,
    ...filtersToPersist
  ];

  const withFilters = !isEveryPropEmpty(lifestylesFilters, persistantFilters);

  return (
    <div className="px-4">
      <div className="flex flex-wrap -m-0.5">
        <LifestylesAuthorsFilterBadge
          filters={lifestylesFilters}
          name={FetchLifestylesFilterFields.AUTHOR_ID}
          onRemoveFilter={filterLifestyles}
        />
        <LifestylesStylesFilterBadge
          filters={lifestylesFilters}
          name={FetchLifestylesFilterFields.STYLE_IDS}
          onRemoveFilter={filterLifestyles}
        />
        <LifestylesGammasFilterBadge
          filters={lifestylesFilters}
          name={FetchLifestylesFilterFields.GAMMA_ID}
          onRemoveFilter={filterLifestyles}
        />
        <LifestylesTonesFilterBadge
          filters={lifestylesFilters}
          name={FetchLifestylesFilterFields.TONE_ID}
          onRemoveFilter={filterLifestyles}
        />
        <LifestylesMaterialsFilterBadge
          filters={lifestylesFilters}
          name={FetchLifestylesFilterFields.MATERIAL_IDS}
          onRemoveFilter={filterLifestyles}
        />
        <LifestylesColorsFilterBadge
          filters={lifestylesFilters}
          name={FetchLifestylesFilterFields.MAIN_COLOR_IDS}
          onRemoveFilter={filterLifestyles}
        />
        <BooleanFilterBadge
          dataGa="lifestyles-blocked-filter-badge-clear"
          filters={lifestylesFilters}
          i18nLabel={words.blocked}
          name={FetchLifestylesFilterFields.BLOCKED}
          onRemoveFilter={filterLifestyles}
        />
        <BooleanFilterBadge
          dataGa="lifestyles-nda-filter-badge-clear"
          filters={lifestylesFilters}
          i18nLabel={words.nda}
          name={FetchLifestylesFilterFields.NDA}
          onRemoveFilter={filterLifestyles}
        />
        <LifestylesTypesFilterBadge
          filters={lifestylesFilters}
          name={FetchLifestylesFilterFields.LIFESTYLE_TYPE_ID}
          onRemoveFilter={filterLifestyles}
        />
        {withFilters && (
          <PureIconButtonHelper
            className="py-1.5 pl-1.5 pr-3 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 m-0.5"
            dataGa="lifestyles-clear-filters-badge"
            i18nText={words.clearFilters}
            icon={IconsEnum.X}
            iconClassName="w-4 h-4"
            onClick={clearLifestylesFilters}
          />
        )}
      </div>
    </div>
  );
}

export default memo<LifestylesAppliedFiltersProps>(LifestylesAppliedFilters);
